import * as api from '../../../api';

const adminRole = 'admin';

export const VALIDATE_TOKEN_REQUEST = 'addDevice/VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_RESPONSE_SUCCESS = 'addDevice/VALIDATE_TOKEN_RESPONSE_SUCCESS';
export const VALIDATE_TOKEN_RESPONSE_FAILED = 'addDevice/VALIDATE_TOKEN_RESPONSE_FAILED';
export const VALIDATE_TOKEN_SUCCESS_REDIRECT_TO_GROUP = 'addDevice/VALIDATE_TOKEN_SUCCESS_REDIRECT_TO_GROUP';
export const validateToken = (serialNumber, token, userId) => async dispatch => {
  dispatch({ type: VALIDATE_TOKEN_REQUEST });

  try {
    const response = await api.validateToken(serialNumber, token, userId);
    if (response.status === 202) {
      dispatch({
        type: VALIDATE_TOKEN_RESPONSE_SUCCESS,
        suggestedInfo: response.data,
        serialNumber,
        connectionString: token,
      });
    }
    if (response.status === 200) {
      const groupsResponse = await api.getUserSystems();
      const userGroup = groupsResponse.data.find(g => g.id === response.data.groupId);
      if (!userGroup) {
        await api.addDevice(serialNumber, token, userGroup.name, userGroup.id, userGroup.address);
        dispatch({ type: VALIDATE_TOKEN_SUCCESS_REDIRECT_TO_GROUP, groupId: response.data.groupId });
      } else if (userGroup.userRole.toLowerCase() === adminRole) {
        dispatch({ type: VALIDATE_TOKEN_RESPONSE_FAILED, error: 'devices.alreadyAdded' });
      } else {
        dispatch({
          type: VALIDATE_TOKEN_RESPONSE_SUCCESS,
          suggestedInfo: { address: userGroup.address, productName: userGroup.name },
          serialNumber,
          connectionString: token,
        });
      }
    }
  } catch (e) {
    dispatch({ type: VALIDATE_TOKEN_RESPONSE_FAILED, error: 'devices.noDeviceFound' });
  }
};

export const ADD_DEVICE_REQUEST = 'addDevice/ADD_DEVICE_REQUEST';
export const ADD_DEVICE_RESPONSE_SUCCESS = 'addDevice/ADD_DEVICE_RESPONSE_SUCCESS';
export const ADD_DEVICE_RESPONSE_FAILED = 'addDevice/ADD_DEVICE_RESPONSE_FAILED';
export const addDevice = (serialNumber, token, groupName, groupId, address) => async dispatch => {
  dispatch({ type: ADD_DEVICE_REQUEST });

  try {
    const response = await api.addDevice(serialNumber, token, groupName, groupId, address);
    if (response.status === 200 || response.status === 201) {
      dispatch({ type: ADD_DEVICE_RESPONSE_SUCCESS, newSystemId: response.data.groupId });
      return;
    }
  } catch (err) {
    console.warn(err);
  }

  dispatch({ type: ADD_DEVICE_RESPONSE_FAILED });
};

export const VALIDATE_TOKEN_INIT = 'addDevice/VALIDATE_TOKEN_INIT';
export const clearToken = () => async dispatch => {
  dispatch({ type: VALIDATE_TOKEN_INIT });
};

export const CLEAR_ERROR_MESSAGE = 'addDevice/CLEAR_ERROR_MESSAGE';
export const clearErrorMessage = () => async dispatch => {
  dispatch({ type: CLEAR_ERROR_MESSAGE });
};
