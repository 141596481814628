import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import DeleteAccountForm from './DeleteAccountForm';
import RemoveConfirmation from '../RemoveConfirmation';
import { sendConfirmationDeleteUser } from '../../../../../api';
import { NotificationManager } from 'react-notifications';
import { eventEmitter, events } from '../../../../../components/tracking/AppInsights';
import { Title } from 'jpi-cloud-web-ui-components';
import DeleteConfirmationForm from '../../../DeleteConfirmation/components/DeleteConfirmationForm/DeleteConfirmationForm';

const DeleteAccountTab = ({
  deleteUser,
  getUserSystems,
  removeSystemTiles,
  unsetSystemAsPrepopulated,
  userId,
  userEmail,
  resetDeleteError,
  history,
  deleteError,
  validateUserCredentials,
  validatePasswordError,
  deletionToken,
}) => {
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');

  const clearUserSystemTiles = async systems => {
    if (systems) {
      await removeSystemTiles(systems.id, userId);
      if (systems.length) {
        systems.slice(1).forEach(async system => {
          await removeSystemTiles(system.id, userId);
        });
      }
    }
  };

  const clearPrepopulatedSystems = async systems => {
    if (systems) {
      await unsetSystemAsPrepopulated(systems.id);
      if (systems.length) {
        systems.slice(1).forEach(async system => {
          await unsetSystemAsPrepopulated(system.id);
        });
      }
    }
  };

  const onRemoveConfirm = async () => {
    resetDeleteError();
    setIsSubmitting(true);
    const systems = await getUserSystems();

    if (await deleteUser(userId, password)) {
      await clearPrepopulatedSystems(systems);
      await clearUserSystemTiles(systems);
    }
    setIsSubmitting(false);
    setShowRemovePopup(false);
  };

  const onRemoveAbort = () => {
    setShowRemovePopup(false);
  };

  const onRemoveButtonClick = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const trimmedPassword = values.password.trim();
    if (await validateUserCredentials(userEmail, trimmedPassword)) {
      setShowRemovePopup(true);
      setPassword(trimmedPassword);
    }

    setSubmitting(false);
  };

  const handleSendConfirmationLink = async () => {
    try {
      const response = await sendConfirmationDeleteUser();

      if (response.status === 202) {
        NotificationManager.success(
          <FormattedMessage
            id="sendConfirmationNotificationSuccess.description"
            defaultMessage="We have sent you a confirmation e-mail on the address specified in profile details."
          />,
          <FormattedMessage id="sendConfirmationNotificationSuccess.title" defaultMessage="E-mail was sent" />,
        );
      }
    } catch (error) {
      NotificationManager.error(<FormattedMessage id="server.error.title" defaultMessage="Server error" />);
      eventEmitter.emit(events.logError, error);
    }
  };

  return (
    <div>
      <Title titleTranslationId="label.system-profile.delete-account-title" defaultMessage="Delete personal account" />
      {deletionToken ? (
        <div className="form--half">
          <DeleteConfirmationForm
            smallButtons={true}
            history={history}
            token={deletionToken}
            clearUserSystemTiles={clearUserSystemTiles}
            clearPrepopulatedSystems={clearPrepopulatedSystems}
            getUserSystems={getUserSystems}
          />
        </div>
      ) : (
        <DeleteAccountForm
          onSubmit={onRemoveButtonClick}
          handleSendConfirmationForDelete={handleSendConfirmationLink}
          requestError={validatePasswordError || deleteError}
        />
      )}
      {showRemovePopup && (
        <RemoveConfirmation
          isShown={showRemovePopup}
          onConfirm={onRemoveConfirm}
          onDiscard={onRemoveAbort}
          requestError={deleteError}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

DeleteAccountTab.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  getUserSystems: PropTypes.func.isRequired,
  removeSystemTiles: PropTypes.func.isRequired,
  unsetSystemAsPrepopulated: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  deleteError: PropTypes.any,
  validateUserCredentials: PropTypes.func.isRequired,
  validatePasswordError: PropTypes.any,
  deletionToken: PropTypes.string,
};

export default DeleteAccountTab;
