import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PremiumFeatureTooltip from '../../layout/PremiumFeatureTooltip';

const DefaultRenderOptions = ({ value, label }) => {
  return (
    <option value={value} key={value}>
      {label}
    </option>
  );
};

DefaultRenderOptions.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const Select = ({
  value,
  options,
  pendingValue,
  renderOptions = null,
  premiumFeatureType,
  disabledDueToOffline,
  onValueChange,
  onChange,
  ...restProps
}) => {
  const [selectedValue, setSelectedValue] = useState(String(value));

  const onSelectValueChange = async e => {
    if (!disabledDueToOffline) setSelectedValue(String(e.target.value)); // TODO: Consider removing whole event from state

    if (onValueChange) onValueChange(e.target.value);

    if (onChange) await onChange(e.target.value);
  };

  useEffect(() => {
    setSelectedValue(String(value));
  }, [value]);

  return (
    <>
      <select
        {...restProps}
        value={selectedValue}
        disabled={disabledDueToOffline || restProps.disabled || pendingValue}
        onChange={onSelectValueChange}
      >
        {options.map(renderOptions || DefaultRenderOptions)}
      </select>

      {restProps.disabled && (
        <PremiumFeatureTooltip premiumFeatureType={premiumFeatureType ? premiumFeatureType : 'number'} />
      )}
      {disabledDueToOffline && <PremiumFeatureTooltip translationId="addDevice.systemSelector.offline" />}
    </>
  );
};

Select.propTypes = {
  value: PropTypes.string,
  pendingValue: PropTypes.string,
  renderOptions: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  premiumFeatureType: PropTypes.bool,
  disabledDueToOffline: PropTypes.bool,
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  onChange: PropTypes.func,
};

export default Select;
