import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { languageSelected } from '../../layout/LanguageSelector/actions';
import { getUserInfo } from '../../AppInitializer/actions';
import './base-legal-page.scss';

const ExistingLanguages = ['sv', 'en', 'de', 'es']; /// TODO: Add new languages if appear

class BaseLegalPage extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    selectedLanguage: PropTypes.string,
    userInfo: PropTypes.object,
    userLoggedIn: PropTypes.bool,
    languageSelected: PropTypes.func.isRequired,
    setLastUsedLanguage: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
  };

  async componentDidMount() {
    const { match, userLoggedIn, userInfo, getUserInfo, languageSelected, history, selectedLanguage } = this.props;
    const { locale } = match.params;
    const requestedLanguage = ExistingLanguages.find(e => e === selectedLanguage) || 'en';

    if (userLoggedIn && !userInfo) {
      await getUserInfo();
    }

    if (locale) {
      if (selectedLanguage !== requestedLanguage) {
        languageSelected(requestedLanguage, userInfo);
      }
      history.push(`${match.url.slice(0, -3)}`);
    }

    languageSelected(selectedLanguage, userInfo);
  }

  render() {
    return <div className="legal-container">{this.props.content}</div>;
  }
}

export default connect(
  ({ language: { selectedLanguage }, app: { userInfo, userLoggedIn } }) => ({
    selectedLanguage,
    userInfo,
    userLoggedIn,
  }),
  {
    languageSelected,
    getUserInfo,
  },
)(BaseLegalPage);
