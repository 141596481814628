import {
  BRANDS_WITH_NOEM_FIRMWARE,
  ELIGIBLE_BRANDS_FOR_HIDING_HISTORY,
  ELIGIBLE_BRANDS_FOR_HIDING_PREMIUM,
} from '../components/constants/constants';

export const selectDevicesFirmwareId = (devices = []) => {
  const [firstDevice = {}] = devices;

  return firstDevice?.firmwareId;
};

const isNoemFirmwareId = firmwareId => /^noem-e\d+$/.test(firmwareId.toLowerCase());
const isBrandWithNoemFirmware = brandId => BRANDS_WITH_NOEM_FIRMWARE.includes(brandId.toLowerCase());

export const isSystemEligibleForHidingPremium = (brandId, firmwareId) => {
  if (!brandId || !firmwareId || (isNoemFirmwareId(firmwareId) && isBrandWithNoemFirmware(brandId))) {
    return false;
  }

  return ELIGIBLE_BRANDS_FOR_HIDING_PREMIUM.includes(brandId.toLowerCase());
};

export const isSystemEligibleForHidingHistory = (brandId, firmwareId) => {
  if (!brandId || !firmwareId || (isNoemFirmwareId(firmwareId) && isBrandWithNoemFirmware(brandId))) {
    return false;
  }

  return ELIGIBLE_BRANDS_FOR_HIDING_HISTORY.includes(brandId.toLowerCase());
};
