import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import NumberInput from '../../../inputs/NumberInput';
import ToggleSwitch from '../../../inputs/ToggleSwitch';
import RadioGroup from '../../../inputs/RadioGroup';
import TextBox from '../../../inputs/TextBox';
import Select from '../../../inputs/Select';
import Slider from '../../../inputs/Slider';
import TimePicker from '../../DeviceScheduling/components/TimePicker';
import { defaultValueLowerLimit } from '../../../constants/constants';
import { Panel, PanelGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from './index';

const UILink = ({ menuItem, followLink }) => (
  <a onClick={() => followLink(menuItem)}>
    {menuItem.number} {menuItem.text.text}
  </a>
);

UILink.propTypes = {
  menuItem: PropTypes.object.isRequired,

  followLink: PropTypes.func.isRequired,
};

const UIHeader = ({ menuItem }) => <h4>{menuItem.text.text}</h4>;

UIHeader.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

const UINumerical = ({ menuItem, changeSetting, isManageEnabled, disabled, premiumFeatureType }) => (
  <div>
    {menuItem.text.text}:
    <NumberInput
      placeholder={menuItem.text.text}
      type="number"
      maxVal={menuItem.metadata.maxValue}
      minVal={menuItem.metadata.minValue}
      stepVal={menuItem.metadata.change || 1}
      unit={menuItem.metadata.unit}
      replaceValues={menuItem.metadata.enumValues}
      valueFormatter={v => {
        return Number(v / (menuItem.metadata.divisor === 0 ? 1 : menuItem.metadata.divisor)).toFixed(
          menuItem.metadata.decimal,
        );
      }}
      value={menuItem.value.integerValue}
      onChange={v => changeSetting(menuItem, v, true, false)}
      disabled={disabled || !isManageEnabled}
      premiumFeatureType={premiumFeatureType}
    />
  </div>
);

UINumerical.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

const UIBoolean = ({ menuItem, changeSetting, isManageEnabled, disabled, premiumFeatureType }) => (
  <div>
    <span>{menuItem.text.text}</span>
    <ToggleSwitch
      isChecked={menuItem.value.integerValue !== 0}
      onChange={v => changeSetting(menuItem, v, true, false)}
      disabled={disabled || !isManageEnabled}
      premiumFeatureType={premiumFeatureType}
      label={`${menuItem.text.text}-${menuItem.parameterId}`}
    />
  </div>
);

UIBoolean.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

const UITextSetting = ({ menuItem, changeSetting, isManageEnabled, disabled }) => {
  const [menuItemVal, setMenuItemVal] = useState(menuItem?.value?.stringValue || '');

  useEffect(() => {
    setMenuItemVal(menuItem?.value?.stringValue ?? '');
  }, [menuItem]);

  return (
    <Input
      onChange={e => {
        setMenuItemVal(e.target.value);
      }}
      onBlur={() => {
        changeSetting(menuItem, menuItemVal, true, false);
      }}
      onKeyPress={e => {
        if (e.charCode === 13) {
          changeSetting(menuItem, menuItemVal, true, false);
        }
      }}
      maxLength={parseInt(menuItem.maxLen)}
      placeholder={menuItem.text.text}
      value={menuItemVal}
      readOnly={!isManageEnabled}
      disabled={disabled || !isManageEnabled}
    />
  );
};

UITextSetting.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

const UIOptionGroup = ({ menuItem, changeSetting, isManageEnabled, disabled, premiumFeatureType }) => (
  <div>
    <span>{menuItem.text.text}</span>
    <RadioGroup
      id={`radioGroup_${menuItem.parameterId}`}
      items={menuItem.options
        .filter(option => option.visible)
        .map(option => {
          {
            return { ...option, disabled: isManageEnabled ? option.disabled : true };
          }
        })}
      comparator={(a, b) => a.value === b.value}
      onChange={i => changeSetting(menuItem, i.value, true, false)}
      selectedItem={menuItem.options.find(option => compareOption(option, menuItem))}
      labelGetter={e => e.text.text}
      disabled={disabled || !isManageEnabled}
      premiumFeatureType={premiumFeatureType}
    />
  </div>
);

UIOptionGroup.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

const UITime = ({ menuItem, changeSetting, disabled }) => {
  const milliseconds = 1000;
  const {
    value = {
      integerValue: 1,
    },
    metadata = {
      change: 1,
      divisor: 1,
    },
    text = {
      text: '',
    },
  } = menuItem;

  const format = value.integerValue % 60 === 0 ? 'HH:mm' : 'HH:mm:ss';
  const time = moment.utc(value.integerValue * milliseconds).format(format);
  const step = metadata.change / metadata.divisor / 60;
  const isWrittable = metadata.isWritable;

  return (
    <div className="timePicker">
      <span>{text.text}</span>
      {isWrittable ? (
        <TimePicker
          time={time}
          onTimeChange={newTime => {
            const timeToServer = moment(newTime, 'HH:mm:ss').diff(moment().startOf('day'), 'seconds');
            changeSetting(menuItem, timeToServer, true, false);
          }}
          disabled={disabled}
          step={step}
        />
      ) : (
        <div className="displaytime">{time}</div>
      )}
    </div>
  );
};

UITime.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const UITextBox = ({ menuItem, changeSetting, disabled, premiumFeatureType }) => (
  <TextBox
    value={menuItem.value.integerValue || menuItem.value.stringValue || ''}
    maxLength={menuItem.uiTextSetting.maxLen}
    type={menuItem.uiTextSetting.isNumeric ? 'numbers' : 'text'}
    typeRegex={menuItem.uiTextSetting.regex}
    textHelper={menuItem.uiTextSetting.inputHelper}
    premiumFeatureType={premiumFeatureType}
    disabled={disabled}
    label={menuItem.text.text || ''}
    onTextBoxValueChange={value => changeSetting(menuItem, value, true, false)}
  />
);

UITextBox.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

const UIDropdown = ({ menuItem, changeSetting, disabled, premiumFeatureType }) => {
  const option = menuItem?.options?.map(
    option =>
      option.visible && {
        type: option.type,
        value: option.value,
        compareType: option.compareType,
        text: option.text.text,
      },
  );
  const val = menuItem?.options?.find(option => compareOption(option, menuItem));
  return (
    <div>
      <span>{menuItem.text.text}</span>
      <Select
        className="dropdown"
        options={option.length ? option : []}
        value={val?.value}
        premiumFeatureType={premiumFeatureType}
        disabled={disabled}
        renderOptions={({ value }) => (
          <option value={value} key={value}>
            {option.find(o => o.value === value).text || value}
          </option>
        )}
        onChange={value => changeSetting(menuItem, Number(value), true, false)}
      />
    </div>
  );
};

UIDropdown.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  isManageEnabled: PropTypes.bool,
};

const compareOption = (option, menuItem) => {
  switch (option.compareType) {
    case 'COMPARE_EQUALS':
      return menuItem.value.integerValue === option.value;
    case 'COMPARE_BIT_AND':
      // TODO???
      return false;
    case 'COMPARE_GREATER_THAN':
      return menuItem.value.integerValue > option.value;
    case 'COMPARE_GREATER_THAN_OR_EQUALS':
      return menuItem.value.integerValue >= option.value;
    case 'COMPARE_LESS_THAN':
      return menuItem.value.integerValue < option.value;
    case 'COMPARE_LESS_THAN_OR_EQUALS':
      return menuItem.value.integerValue <= option.value;
    case 'COMPARE_NOT_EQUALS':
      return menuItem.value.integerValue !== option.value;
    case 'ERR_UNKNOWN':
      return false;
  }
};

const calculateOptions = menuItem => {
  const item = menuItem.options.find(option => compareOption(option, menuItem));
  return item?.text?.text || calculateMetadata(menuItem);
};

const calculateMetadata = menuItem => {
  if (Number.isInteger(menuItem.value.integerValue)) {
    var decimalValue = menuItem.value.integerValue / menuItem.metadata.divisor;
    if (decimalValue < defaultValueLowerLimit || menuItem.value.integerValue < defaultValueLowerLimit) {
      return '--';
    }
  }

  if (menuItem.metadata.unit && menuItem.metadata.unit.trim() === '$') {
    return `${menuItem.metadata.unit} ${decimalValue.toFixed(menuItem.metadata.decimal)}`;
  }
  return `${decimalValue.toFixed(menuItem.metadata.decimal)} ${menuItem.metadata.unit}`;
};

const calculateInteger = menuItem => {
  if (menuItem.options && menuItem.options.length !== 0) {
    return `${calculateOptions(menuItem)}`;
  }
  return calculateMetadata(menuItem);
};

const convertDate = dateRaw => {
  return moment('2010-01-01').add('days', dateRaw).format('L');
};

const convertTime = timeRaw => {
  return moment().startOf('day').add(timeRaw, 'seconds').format('HH:mm');
};

const getInfoParameterValue = menuItem => {
  switch (menuItem.metadata.variableType.toLowerCase()) {
    case 'integer':
      return `${calculateInteger(menuItem)}`;
    case 'string':
      return `${menuItem.value.stringValue || '--'} ${menuItem.metadata.unit}`;
    case 'binary':
      return menuItem.value.stringValue;
    case 'date':
      return convertDate(menuItem.value.integerValue);
    case 'time':
      return `${convertTime(menuItem.value.integerValue)}`;
    default:
      return null;
  }
};

const UIInfoParameter = ({ menuItem, disabled }) => (
  <div>
    {menuItem.text.text}
    <span disabled={disabled} className="bold-text">
      {getInfoParameterValue(menuItem)}{' '}
    </span>
  </div>
);

UIInfoParameter.propTypes = {
  menuItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

const convertStrToTime = str => {
  return moment.duration(moment(str, 'hh:mm').diff(moment().startOf('day'))).asSeconds();
};

const UITimeSetting = ({ menuItem, changeSetting, isManageEnabled, disabled }) => {
  const convertedTime = convertTime(menuItem.value.integerValue);
  return (
    <Input
      placeholder={menuItem.text.text}
      type="time"
      defaultValue={convertedTime}
      onBlur={e => changeSetting(menuItem, convertStrToTime(e.target.value), true, false)}
      readOnly={!isManageEnabled}
      disabled={disabled}
      key={convertedTime}
    />
  );
};

UITimeSetting.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
};

const UIInfoText = ({ menuItem }) => <div>{menuItem.text.text}</div>;

UIInfoText.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

const UIEvent = ({ menuItem, processEvent, disabled }) => (
  <Button
    onClick={() => {
      if (menuItem.warnText.text !== '') {
        alert(menuItem.warnText.text);
        return;
      }
      processEvent(menuItem);
    }}
    disabled={disabled}
    type="button"
    className="button--default"
  >
    {menuItem.text.text}
  </Button>
);

UIEvent.propTypes = {
  menuItem: PropTypes.object.isRequired,
  processEvent: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const convertDateTime = menuItem => {
  let date = moment();
  if (menuItem.timeIsRelative) {
    date = moment().add('seconds', menuItem.timeValue.integerValue);
  } else {
    date = moment('2010-01-01')
      .add('days', menuItem.dateValue.integerValue)
      .add('seconds', menuItem.timeValue.integerValue);
  }

  const diff = moment.duration(date.diff(moment()));

  if (diff.hours() > 1) return `${diff.hours()} hours`;
  if (diff.hours() === 1) return `${diff.hours()} hour`;
  if (diff.minutes() > 1) return `${diff.minutes()} minutes`;
  if (diff.minutes() === 1) return `${diff.minutes()} minute`;
  return '';
};

const UIInfoCountdown = ({ menuItem }) => (
  <div>
    {menuItem.text.text} {convertDateTime(menuItem)}
  </div>
);

UIInfoCountdown.propTypes = {
  menuItem: PropTypes.object.isRequired,
};

const UISlider = ({ menuItem, changeSetting, isManageEnabled, disabled, premiumFeatureType }) => {
  return (
    <div className="slider-item">
      <p>{menuItem.text.text}:</p>
      <Slider
        placeholder={menuItem.text.text}
        type="slider"
        maxVal={menuItem.metadata.maxValue / menuItem.metadata.divisor || menuItem.metadata.maxValue}
        minVal={menuItem.metadata.minValue / menuItem.metadata.divisor || menuItem.metadata.minValue}
        stepVal={menuItem.metadata.change / menuItem.metadata.divisor || 1}
        unit={menuItem.metadata.unit}
        value={menuItem.value.integerValue / menuItem.metadata.divisor || menuItem.value.integerValue}
        onChange={v => changeSetting(menuItem, v * menuItem.metadata.divisor, true, false)}
        disabled={disabled || !isManageEnabled}
        premiumFeatureType={premiumFeatureType}
      />
    </div>
  );
};

UISlider.propTypes = {
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  disabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
};

const UIGroup = ({ accordionId, menuItem, isManageEnabled, premiumFeatureType, changeSetting, isDemoUser }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [open, setOpen] = useState(false);

  const handleSelect = newActiveKey => {
    setActiveKey(newActiveKey);
    setOpen(!open);
  };

  const iconClass = open ? 'angle-down' : 'angle-right';

  return (
    <PanelGroup accordion activeKey={activeKey} onSelect={handleSelect} accordionId={accordionId}>
      <Panel eventKey={accordionId}>
        <Panel.Heading>
          <Panel.Title toggle>
            {menuItem.text.text}
            <FontAwesomeIcon icon={iconClass} size="1x" pull="right" className={iconClass} />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>
          <div>
            {menuItem.rows.map((row, index) => {
              return (
                <MenuItem
                  key={index}
                  menuItem={row}
                  isManageEnabled={isManageEnabled}
                  premiumFeatureType={premiumFeatureType}
                  changeSetting={changeSetting}
                  isDemoUser={isDemoUser}
                />
              );
            })}
          </div>
        </Panel.Body>
      </Panel>
    </PanelGroup>
  );
};

UIGroup.propTypes = {
  accordionId: PropTypes.number.isRequired,
  menuItem: PropTypes.object.isRequired,
  changeSetting: PropTypes.func.isRequired,
  isManageEnabled: PropTypes.bool,
  premiumFeatureType: PropTypes.string,
  isDemoUser: PropTypes.bool,
};

export {
  UIInfoText,
  UITimeSetting,
  UIInfoParameter,
  UIOptionGroup,
  UITextSetting,
  UIBoolean,
  UINumerical,
  UIHeader,
  UILink,
  UIEvent,
  UIInfoCountdown,
  UITime,
  UITextBox,
  UIDropdown,
  UISlider,
  UIGroup,
};
