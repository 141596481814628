import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import { Button, Input } from 'jpi-cloud-web-ui-components';
import { InputDropdown } from '../../../inputs/Dropdown';

import { address, defaultMaxLength, noRegion } from '../../../constants/constants';
import { formatErrorMessage } from '../../../../localization/message-formatting';

const addDeviceSchema = yup.object().shape({
  groupName: yup.string().trim().max(100, 'group-name.max-length').required('group-name.required'),
  address: yup.object().shape({
    lineOne: yup
      .string()
      .trim()
      .max(defaultMaxLength, 'address.line-one.maxlength')
      .required('address.line-one.required')
      .matches(address.pattern, 'address.line-one.invalid-format'),
    lineTwo: yup
      .string()
      .trim()
      .max(defaultMaxLength, 'address.line-two.maxlength')
      .matches(address.pattern, 'address.line-two.invalid-format'),
    postalCode: yup
      .string()
      .trim()
      .max(address.postalCode.maxLength, 'postal-code.maxlength')
      .required('postal-code.required')
      .matches(address.postalCode.regex, 'postal-code.regexp'),
    city: yup
      .string()
      .trim()
      .max(address.cityNameMaxLength, 'city.maxlength')
      .matches(address.pattern, 'city.invalid-format')
      .required('city.required'),
    country: yup.object().nullable().required('country.required'),
    region: yup
      .string()
      .trim()
      .when('country', {
        is: country => country && country.regions && country.regions.length > 0,
        then: yup
          .string()
          .required('region.required')
          .max(address.regionNameMaxLength, 'region.maxlength')
          .matches(address.pattern, 'region.invalid-format'),
        otherwise: yup
          .string()
          .max(address.regionNameMaxLength, 'region.maxlength')
          .matches(address.pattern, 'region.invalid-format'),
      }),
  }),
});

const errorMessages = {
  'group-name.max-length': {
    id: 'add-device.error.validation.group-name.max-length',
    defaultMessage: 'Group name cannot be longer than 100 characters',
  },
  'group-name.required': {
    id: 'add-device.error.validation.group-name.required',
    defaultMessage: 'Group name is mandatory field',
  },
  'address.line-one.required': {
    id: 'address.error.validation.line-one.required',
    defaultMessage: 'Address line 1 is mandatory field',
  },
  'address.line-one.maxlength': {
    id: 'address.error.validation.line-one.maxlength',
    defaultMessage: 'Address line 1 cannot be longer than 255 characters',
  },
  'address.line-one.invalid-format': {
    id: 'address.error.validation.line-one.invalid-format',
    defaultMessage: 'Address line 1 has invalid format',
  },
  'address.line-two.maxlength': {
    id: 'address.error.validation.line-two.maxlength',
    defaultMessage: 'Address line 2 cannot be longer than 255 characters',
  },
  'address.line-two.invalid-format': {
    id: 'address.error.validation.line-two.invalid-format',
    defaultMessage: 'Address line 2 has invalid format',
  },
  'postal-code.maxlength': {
    id: 'postal-code.error.validation.maxlength',
    defaultMessage: 'Postal code cannot be longer than 20 characters',
  },
  'postal-code.required': {
    id: 'postal-code.error.validation.required',
    defaultMessage: 'Postal code is mandatory field',
  },
  'postal-code.regexp': {
    id: 'postal-code.error.validation.regexp',
    defaultMessage: 'Postal code has an invalid format',
  },
  'city.maxlength': {
    id: 'city.error.validation.maxlength',
    defaultMessage: 'City cannot be longer than 100 characters',
  },
  'city.required': {
    id: 'city.error.validation.required',
    defaultMessage: 'City is mandatory field',
  },
  'city.invalid-format': {
    id: 'city.error.validation.invalid-format',
    defaultMessage: 'City has invalid format',
  },
  'country.required': {
    id: 'country.error.validation.required',
    defaultMessage: 'Country is mandatory field',
  },
  'region.required': {
    id: 'region.error.validation.required',
    defaultMessage: 'Region is mandatory field',
  },
  'region.maxlength': {
    id: 'region.error.validation.maxlength',
    defaultMessage: 'Region cannot be longer than 100 characters',
  },
  'region.invalid-format': {
    id: 'region.error.validation.invalid-format',
    defaultMessage: 'Region has invalid format',
  },
};

class AddDeviceForm extends React.Component {
  static propTypes = {
    systems: PropTypes.array.isRequired,
    countries: PropTypes.arrayOf(PropTypes.object),
    suggestedAddress: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    goToPrevStep: PropTypes.func.isRequired,
    suggestedInfo: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    intl: PropTypes.object,
  };

  state = {
    showLegalInfoModal: false,
    legalInfoType: null,
    countryRegion: [],
  };

  toggleLegalModal = legalType => {
    this.setState({
      showLegalInfoModal: !this.state.showLegalInfoModal,
      legalInfoType: legalType,
    });
  };

  render() {
    const { onSubmit, systems, countries, suggestedAddress, suggestedInfo, goToPrevStep, loading, intl } = this.props;

    return (
      <div className="row">
        <div className="col-lg-6">
          <Formik
            initialValues={{
              groupName: suggestedInfo && suggestedInfo.productName,
              system: systems.length === 1 ? systems[0] : { id: '' },
              address: {
                lineOne: (suggestedAddress && suggestedAddress.lineOne) || '',
                lineTwo: (suggestedAddress && suggestedAddress.lineTwo) || '',
                postalCode: (suggestedAddress && suggestedAddress.postalCode) || '',
                city: (suggestedAddress && suggestedAddress.city) || '',
                country:
                  suggestedAddress &&
                  suggestedAddress.country &&
                  suggestedAddress.country.name &&
                  suggestedAddress.country.name !== ''
                    ? suggestedAddress.country
                    : null,
                region: (suggestedAddress && suggestedAddress.region) || '',
              },
            }}
            validationSchema={addDeviceSchema}
            onSubmit={onSubmit}
            countries={countries}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
              <form className="form--half" onSubmit={handleSubmit}>
                <FormattedMessage id="addDevice.form.groupName" defaultMessage="Group name">
                  {placeholder => (
                    <Input
                      placeholder={placeholder}
                      type="text"
                      name="groupName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.groupName}
                      error={
                        errors.groupName &&
                        touched.groupName &&
                        formatErrorMessage(intl, errorMessages, errors.groupName)
                      }
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="addDevice.form.lineOne" defaultMessage="Address Line 1">
                  {placeholder => (
                    <Input
                      placeholder={placeholder}
                      type="text"
                      name="address.lineOne"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.lineOne}
                      error={
                        getIn(errors, 'address.lineOne') &&
                        getIn(touched, 'address.lineOne') &&
                        formatErrorMessage(intl, errorMessages, getIn(errors, 'address.lineOne'))
                      }
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="addDevice.form.lineTwo" defaultMessage="Address Line 2">
                  {placeholder => (
                    <Input
                      placeholder={placeholder}
                      type="text"
                      name="address.lineTwo"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.lineTwo}
                      error={
                        getIn(errors, 'address.lineTwo') &&
                        getIn(touched, 'address.lineTwo') &&
                        formatErrorMessage(intl, errorMessages, getIn(errors, 'address.lineTwo'))
                      }
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="addDevice.form.postalCode" defaultMessage="Zip Code \/ Postal Code">
                  {placeholder => (
                    <Input
                      placeholder={placeholder}
                      type="text"
                      name="address.postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.postalCode}
                      error={
                        getIn(errors, 'address.postalCode') &&
                        getIn(touched, 'address.postalCode') &&
                        formatErrorMessage(intl, errorMessages, getIn(errors, 'address.postalCode'))
                      }
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="addDevice.form.city" defaultMessag="City">
                  {placeholder => (
                    <Input
                      placeholder={placeholder}
                      type="text"
                      name="address.city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.city}
                      error={
                        getIn(errors, 'address.city') &&
                        getIn(touched, 'address.city') &&
                        formatErrorMessage(intl, errorMessages, getIn(errors, 'address.city'))
                      }
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id="addDevice.form.country" defaultMessage="Country">
                  {placeholder => (
                    <InputDropdown
                      placeholder={placeholder}
                      selectedItem={values.address.country}
                      id="country"
                      name="address.country"
                      items={countries}
                      error={
                        getIn(errors, 'address.country') &&
                        getIn(touched, 'address.country') &&
                        formatErrorMessage(intl, errorMessages, getIn(errors, 'address.country'))
                      }
                      labelGetter={e => e.name}
                      onSelect={c => {
                        setFieldTouched('address.region', false);
                        setFieldValue('address.region', '');
                        if (typeof c !== 'string') {
                          c.regions.length > 0
                            ? this.setState({ countryRegion: [noRegion, ...c.regions] })
                            : this.setState({ countryRegion: c.regions });
                          setFieldValue('address.country', c);
                          return;
                        }
                        const matchedCountries =
                          (c &&
                            countries.filter(country => {
                              return country.name.toLowerCase() === c.toLowerCase().trim();
                            })) ||
                          [];
                        if (matchedCountries.length > 0) {
                          const country = matchedCountries.pop();
                          country.regions.length > 0
                            ? this.setState({ countryRegion: [noRegion, ...country.regions] })
                            : this.setState({ countryRegion: country.regions });
                          setFieldValue('address.country', country);
                        } else {
                          this.setState({ countryRegion: [] });
                          setFieldValue('address.country', null);
                        }
                      }}
                      itemFilter={(country, input) => {
                        return !input || country.name.toLowerCase().startsWith(input.toLowerCase().trim());
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id={
                    this.state.countryRegion && this.state.countryRegion.length > 0
                      ? 'label.region'
                      : 'register.input-region'
                  }
                  defaultMessage="Region (Optional)"
                >
                  {placeholder =>
                    this.state.countryRegion && this.state.countryRegion.length > 0 ? (
                      <InputDropdown
                        placeholder={placeholder}
                        id={Date.now().toString(36) + Math.random().toString(36).substr(2)}
                        name="region"
                        items={this.state.countryRegion}
                        error={
                          getIn(errors, 'address.region') &&
                          getIn(touched, 'address.region') &&
                          formatErrorMessage(intl, errorMessages, getIn(errors, 'address.region'))
                        }
                        labelGetter={e => e.name}
                        onSelect={c => {
                          if (typeof c !== 'string') {
                            setFieldValue('address.region', c.name);
                            return;
                          }
                          const matchedRegion =
                            (c &&
                              this.state.countryRegion.filter(region => {
                                return region.name.toLowerCase() === c.toLowerCase().trim();
                              })) ||
                            [];
                          matchedRegion.length > 0
                            ? setFieldValue('address.region', matchedRegion.pop().name)
                            : setFieldValue('address.region', '');
                        }}
                        itemFilter={(region, input) => {
                          return !input || region.name.toLowerCase().startsWith(input.toLowerCase().trim());
                        }}
                      />
                    ) : (
                      <Input
                        placeholder={placeholder}
                        type="text"
                        name="region"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.region}
                        error={
                          getIn(errors, 'address.region') &&
                          getIn(touched, 'address.region') &&
                          formatErrorMessage(intl, errorMessages, getIn(errors, 'address.region'))
                        }
                      />
                    )
                  }
                </FormattedMessage>
                <div className="button-wrapper">
                  <Button className="button--default" type="button" onClick={goToPrevStep}>
                    <FormattedMessage id="addDevice.form.back" defaultMessage="Back" />
                  </Button>
                  <Button className="button--secondary" type="submit" disabled={loading}>
                    <FormattedMessage id="addDevice.form.add" defaultMessage="Add" />
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(AddDeviceForm);
